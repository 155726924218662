import React, { useEffect, useState } from 'react';
import './FadeInText.css'; // Import CSS for fade-in animation

const FadeInText = ({ text, layout = '', isVisible }) => {
    const [words, setWords] = useState([]);
    const [keyId, setKeyId] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);


    useEffect(() => {
        const animationPlayed = localStorage.getItem('fadeInTextAnimated');
        if (!animationPlayed) {
          const timeoutId = setTimeout(() => {
            setWords(text.split(' '));
            setKeyId(prev => prev + 1);
            localStorage.setItem('fadeInTextAnimated', 'true');
            setHasAnimated(true);
          }, 500);
    
          return () => {
            clearTimeout(timeoutId);
          };
        } else {
          setWords(text.split(' '));
          setKeyId(prev => prev + 1);
          setHasAnimated(true);
        }
      }, [text, layout]);


    return (
        <div key={keyId} className={`fade-in-text ${layout} ${isVisible ? 'visible' : 'hidden'}`}>
            {words.map((word, index) => (
                <span
                    key={`${word}-${index}`}
                    className="fade-in"
                    style={{
                        opacity: 0,
                        filter: 'blur(4px)',
                        animation: `fade-in 1s ${0.5 + index * 0.1}s forwards cubic-bezier(0.11, 0, 0.5, 0)`,
                        marginRight: index !== words.length - 1 ? '0.2em' : '0'
                    }}>
                    {word}
                </span>
            ))}
        </div>
    );
};

export default FadeInText;