import React from 'react';
import './Navbar.css'; 
import { NavLink } from 'react-router-dom';


const Navbar = () => {
  return (
    <nav>
      <ul className="navbar">
        <li><NavLink to="/" activeClassName="active">Home</NavLink></li>
        <li><NavLink to="/semis" activeClassName="active">Semis</NavLink></li>
        <li><NavLink to="/particles" activeClassName="active">Particles</NavLink></li>
        <li><NavLink to="/logotest" activeClassName="active">Logo</NavLink></li>
      </ul>
    </nav>
  );
};

export default Navbar;
