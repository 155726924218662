import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Semis from './Semis';
import Particles from './Particles';
import Contact from './Contact';
import LogoTest from './LogoTest';
import Footer from './Footer';
import LogoFlavor from './LogoFlavor';

/*
const LogoFlavorConfig = {
  '/': { layout: 'home', text: 'Seifdune is a global investment firm building and capitalizing category-defining technology businesses.' },
  '/semis': { layout: 'semis', text: '' },
  '/particles': { layout: 'particles', text: 'We make early, principled, high-conviction bets in ambitious companies with the potential to change the arc of history.' },
};
*/

const AppContent = () => {
  /* const { text, layout } = LogoFlavorConfig[location.pathname] || { text: '', layout: '' }; */
  const location = useLocation();
  const [transitionClass, setTransitionClass] = useState(location.pathname === '/' ? 'home' : 'semis');
  const [isTextVisible, setIsTextVisible] = useState(true);

  useEffect(() => {
    const applyHoverEffect = () => {
      if ('ontouchstart' in document.documentElement) {
        // Add hover-mobile class to all .card elements
        document.querySelectorAll('.card').forEach(card => {
          card.classList.add('hover-mobile');
        });
      }
    };

    // Observe DOM changes for dynamically added .card elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        applyHoverEffect(); // Run hover effect when new .card elements are added to the DOM
      });
    });

    // Start observing changes in the body
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial call to apply hover effect in case .card elements are already in the DOM
    applyHoverEffect();

    // Cleanup the observer when component unmounts
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleLocationChange = () => {
      setIsTextVisible(false);

      const timeoutId = setTimeout(() => {
        if (location.pathname === '/') {
          setTransitionClass('home');
        } else if (location.pathname === '/semis') {
          setTransitionClass('semis');
        }
        setIsTextVisible(true);
      }, 500); // Match this duration to your CSS transition duration

      return () => clearTimeout(timeoutId);
    };

    handleLocationChange();
  }, [location.pathname]);
  return (
    <div className='app-container'>
      {/*<LogoFlavor text={text} layout={layout} />*/}
      <LogoFlavor transitionClass={transitionClass} isTextVisible={isTextVisible} />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/semis" element={<Semis />} />
        <Route path="/particles" element={<Particles />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/logotest" element={<LogoTest />} />
      </Routes>  
      <Footer />
    </div>
   
    );
  };
  const App = () => {
    return (
      <Router> 
        {/* <Navbar /> */}
        <AppContent />
       
      </Router>
    );
  };
  

export default App;
