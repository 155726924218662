import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LogoFlavor.css';
import FadeInText from './FadeInText';
import LogoDark from './assets/LogoDark.svg';

/* const LogoFlavor = ({ transitionClass, isTextVisible }) => {

	 return (
    <div className={`logo-flavor-container ${transitionClass}`}>
    <Link to={transitionClass === 'home' ? '/semis' : '/'} className={`img-container ${transitionClass}`}>
      <img src={LogoDark} alt="Logo" />
    </Link>
    <FadeInText text='Seifdune is a global investment firm building and capitalizing category-defining technology businesses.' layout={transitionClass} isVisible={isTextVisible}/>
  </div>

  );
};

export default  LogoFlavor; */
const LogoFlavor = ({ transitionClass, isTextVisible }) => {
  const [displayText, setDisplayText] = useState('Seifdune is a global investment firm building and capitalizing category-defining technology businesses.');
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (transitionClass === 'home') {
      setDisplayText('Seifdune is a global investment firm building and capitalizing category-defining technology businesses.');
    } else if (transitionClass === 'semis') {
      /* setDisplayText('We hatch new ideas, build exceptional products, and launch world-class companies targeting unique opportunities in semis.'); */
      setDisplayText('Investing in the next generation of world-class semiconductor businesses.');
    }
  }, [transitionClass]);

  useEffect(() => {
    if (!isTextVisible) {
      setIsTransitioning(true);
      const timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 100); // Match this duration to your CSS transition duration
      return () => clearTimeout(timeoutId);
    }
  }, [isTextVisible]);

  return (
      <div className={`logo-flavor-container ${transitionClass} ${isTransitioning ? 'transitioning' : ''}`}>
        <Link to={transitionClass === 'home' ? '/semis' : '/'} className={`img-container ${transitionClass}`}>
        <img src={LogoDark} alt="Logo" />
        </Link>
        <FadeInText text={displayText} layout={transitionClass} isVisible={isTextVisible} />
      </div>
  );
};

export default LogoFlavor;

