import React, {useState, useEffect} from 'react';
import TextAnimation from './TextAnimation';
import './Particles.css';
import FadeInText from './FadeInText';
import {ReactComponent as AlifLogo} from './assets/alif-logo.svg';
import {ReactComponent as AkeanaLogo} from'./assets/akeana-logo.svg';
import {ReactComponent as RetymLogo} from './assets/retym-logo.svg';
import LogoFlavor from './LogoFlavor';

const updateCursor = ({ x, y }) => {
  document.documentElement.style.setProperty('--x', Math.round(x))
  document.documentElement.style.setProperty('--y', Math.round(y))
}

document.body.addEventListener('pointermove', updateCursor)
const Particles = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
  }, 3000); // Adjust the delay as needed
  return () => clearTimeout(timeout);
}, []);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className='particles-container'>
     
      <div className='card-container-wrapper'>
        <ul className={`card-container-p ${isVisible ? 'visible' : ''}`}>
          <a href="https://alifsemi.com/">
            <li className='card-p'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <div className='logo-wrap'>
                <AlifLogo />
              </div>
              <p className='paragraph'>
                Next generation microcontrollers, powering AI at the edge.
             </p>
            </li>
          </a>
            <li className='card-p'>
              <div className='logo-wrap'>
                <AkeanaLogo />
              </div>
              <p className='paragraph'>
                RISC-V technology, elevated to world-class performance levels.
              </p>
            </li>
            <li className='card-p'>
              <div className='logo-wrap'>
              <RetymLogo />
              </div>
            <p className='paragraph'>
              Leading-edge datacenter and service provider infrastructure, delivered at the speed of light.
            </p>
              </li>
            <li className='card-p'>
            
            </li>
            <li className='card-p'>
            
            </li>
            <li className='card-p'>
            </li>
        </ul>
      </div>
    </div>
  );
};

export default Particles;