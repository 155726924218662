import React, { useEffect } from 'react';
import './LogoTest.css';




const LogoTest = () => {
    return (
      <div className='particles-container'>
          <div className='logo-test'>
            <h3 className='wave'>
              Seifdune
            </h3>
            <h3 className='wave'>
                Seifdune
            </h3>
          </div>
          <div className="waviy">
   					<span style={{"--i":1}}>S</span>
   					<span style={{"--i":2}}>e</span>
   					<span style={{"--i":3}}>i</span>
   					<span style={{"--i":4}}>f</span>
   					<span style={{"--i":5}}>d</span>
   					<span style={{"--i":6}}>u</span>
   					<span style={{"--i":7}}>n</span>
   					<span style={{"--i":8}}>e</span>
  				</div>
      </div>  
    );
};

export default LogoTest;