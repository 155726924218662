import React from 'react';
import './Footer.css'; // Import CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <a href="mailto:armaan@seifdune.com" className="left-text">armaan@seifdune.com</a>
      <div className="right-text">San Francisco, CA</div>
    </footer>
  );
};

export default Footer;