import React, { useState, useEffect } from 'react';
import TextAnimation from './TextAnimation';
import './Semis.css';
import {ReactComponent as AlifLogo} from './assets/alif-logo.svg';
import {ReactComponent as AkeanaLogo} from'./assets/akeana-logo.svg';
import {ReactComponent as RetymLogo} from './assets/retym-logo.svg';
import {ReactComponent as LumilensLogo} from './assets/lumilens-logo.svg';

import LogoDark from './assets/LogoDark.svg';
import LogoFlavor from './LogoFlavor';

const updateCursor = ({ x, y }) => {
  document.documentElement.style.setProperty('--x', Math.round(x))
  document.documentElement.style.setProperty('--y', Math.round(y))
}

document.body.addEventListener('pointermove', updateCursor)

const Semis = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [isVisible, setIsVisible] = useState(false); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
  }, 2500); 
  return () => clearTimeout(timeout);
}, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className='semis-container'>
      <div className='card-container-wrapper'>
      <ul className={`card-container ${isVisible ? 'visible' : ''}`}>
        <a href="https://alifsemi.com/">
          <li className='card'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className='logo-wrap alif'>
              <AlifLogo />
            </div>
            <p className='paragraph'>
              Next generation microcontrollers, powering AI at the edge
            </p>
          </li>
        </a>
        <a href="https://www.akeana.com/">
          <li className='card'>
            <div className='logo-wrap'>
              <AkeanaLogo />
            </div>
            <p className='paragraph'>
              RISC-V technology, elevated to world-class performance levels
            </p>
          </li>
        </a>
        <a href="https://www.retym.com/">
          <li className='card'>
            <div className='logo-wrap'>
              <RetymLogo />
            </div>
            <p className='paragraph'>
              Leading-edge optical infrastructure for the datacenter, delivered at the speed of light
            </p>
          </li>
        </a>  
        <a href="https://www.lumilens.com/">
          <li className='card'>
            <div className='logo-wrap'>
              <LumilensLogo />
            </div>
            <p className='paragraph'>
              Photonics products for high performance computing
            </p>
          </li>
        </a>                              
      </ul>
      </div>
      
    </div>
  );
};

export default Semis;