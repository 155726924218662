import React, { useState, useEffect } from 'react';
import TextAnimation from './TextAnimation';
import './Home.css';
import FadeInText from './FadeInText';
import LogoFlavor from './LogoFlavor';

const Home = () => {
  const [moveUp, setMoveUp] = useState(false);

  const handleLogoClick = () => {
    setMoveUp(true);
  };
  
  return (
    <div className='home-container'>
    </div>
    
  );
};

export default Home;