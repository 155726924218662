import React from 'react';

const Contact = () => {
  return (
    <div>
      {/* Your animation content here */}
      <h2>
        Contacts
      </h2>
    </div>
  );
};

export default Contact;